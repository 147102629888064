.cr-container{
    width: 100vw;
    height: 100vh;
    /* max-height: 100vh;
    overflow-y: hidden; */

    margin: 0;
    padding: 0;

    background: #222;
    color: #f2f2f2;
}

.cr-header{
    margin: 0;
    padding: 0;
    height: 5vh;
    max-height: 5vh;
    background: #121314;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.cr-header-left{
    width: 90%;
    padding: 0 20px;
}

.cr-header-left p{
    margin: 0;
    padding: 0;

    font-size: 1.5em;
}

.cr-header-right{
    width: 10%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.cr-messages{
    margin: 0;
    padding: 0;
    height: 88vh;
    max-height: 88vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
}

.cr-messages::-webkit-scrollbar{
    display: none;
}

.cr-create{
    margin: 0;
    padding: 0;
    height: 7vh;
    background: #121314;

    display: flex;
    justify-content: center;
    align-items: center;
}