@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
body, .home, html{
    margin: 0;
    padding: 0;
    /* overflow-x: hidden */
    font-family: 'Ubuntu', sans-serif !important;
}
.li-container{
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 120% 120%;

    -webkit-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Ubuntu', sans-serif !important;
}

.li-header{
    padding: 0;
    margin: 0px 0px 20px 0px;
    font-size: 3em;
    color: whitesmoke;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
}

*:focus {
    outline: none;
}

.li-buttonContainer button{
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 30px;
    padding: 10px;
    font-size: 2em;
    color: white;
    background: #222222;
}

.li-buttonContainer{
    width: 40%;
    padding: 3px;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
.cr-container{
    width: 100vw;
    height: 100vh;
    /* max-height: 100vh;
    overflow-y: hidden; */

    margin: 0;
    padding: 0;

    background: #222;
    color: #f2f2f2;
}

.cr-header{
    margin: 0;
    padding: 0;
    height: 5vh;
    max-height: 5vh;
    background: #121314;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.cr-header-left{
    width: 90%;
    padding: 0 20px;
}

.cr-header-left p{
    margin: 0;
    padding: 0;

    font-size: 1.5em;
}

.cr-header-right{
    width: 10%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.cr-messages{
    margin: 0;
    padding: 0;
    height: 88vh;
    max-height: 88vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
}

.cr-messages::-webkit-scrollbar{
    display: none;
}

.cr-create{
    margin: 0;
    padding: 0;
    height: 7vh;
    background: #121314;

    display: flex;
    justify-content: center;
    align-items: center;
}
.ci-message{
    max-width: 100%;
    margin: 10px;
}

.ci-container{
    padding: 10px;
    max-width: 70%;
    min-width: 20%;
    height: auto;
    word-wrap: break-word;
    border-radius: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ci-left{
    width: 60px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ci-right{
    flex-grow: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ci-image{
    border-radius: 999em;
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
}

.ci-body{
    margin: 0;
    padding: 0 10px;
}

.ci-body p{
    margin: 0;
    padding: 0;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}

.ci-additional{
    margin: 0;
    padding: 0 10px;
    text-align: right;
}

.ci-additional p{
    margin: 0;
    padding: 0;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}

.ci-sent{
    float: right;
    background: rgb(118,200,147);
    background: linear-gradient(90deg, rgba(118,200,147,1) 0%, rgba(52,160,164,1) 100%);
}

.ci-received{
    float: left;
    background: rgb(52,160,164);
    background: linear-gradient(90deg, rgba(52,160,164,1) 0%, rgba(26,117,159,1) 100%);
}
.sm-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    max-height: 100%;
}

.sm-input-parent{
    width: 90vw;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sm-button-parent{
    width: 10vw;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sm-message-input{
    width: 100%;
    padding: 8px 15px;
    font-size: .9em;
    border-radius: 30px;
    outline: none;
    box-sizing: border-box;
    border: none;
}

.sm-message-input:focus{
    outline: none;
}

.sm-message-input-container{
    width: 100%;
    padding: 3px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

.sm-buttonContainer button{
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 30px;
    padding: 5px;
    font-size: 1.5em;
    color: white;
    background: #222222;
}

.sm-buttonContainer{
    width: 90%;
    padding: 3px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
.buttonContainer button{
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 30px;
    padding: 5px;
    font-size: 1.2em;
    color: white;
    background: #222222;
}

.buttonContainer{
    width: 100%;
    padding: 3px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
