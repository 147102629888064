.buttonContainer button{
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 30px;
    padding: 5px;
    font-size: 1.2em;
    color: white;
    background: #222222;
}

.buttonContainer{
    width: 100%;
    padding: 3px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}