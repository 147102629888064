@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.li-container{
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 120% 120%;

    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Ubuntu', sans-serif !important;
}

.li-header{
    padding: 0;
    margin: 0px 0px 20px 0px;
    font-size: 3em;
    color: whitesmoke;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
}

*:focus {
    outline: none;
}

.li-buttonContainer button{
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 30px;
    padding: 10px;
    font-size: 2em;
    color: white;
    background: #222222;
}

.li-buttonContainer{
    width: 40%;
    padding: 3px;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}