.ci-message{
    max-width: 100%;
    margin: 10px;
}

.ci-container{
    padding: 10px;
    max-width: 70%;
    min-width: 20%;
    height: auto;
    word-wrap: break-word;
    border-radius: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ci-left{
    width: 60px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ci-right{
    flex-grow: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ci-image{
    border-radius: 999em;
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
}

.ci-body{
    margin: 0;
    padding: 0 10px;
}

.ci-body p{
    margin: 0;
    padding: 0;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}

.ci-additional{
    margin: 0;
    padding: 0 10px;
    text-align: right;
}

.ci-additional p{
    margin: 0;
    padding: 0;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}

.ci-sent{
    float: right;
    background: rgb(118,200,147);
    background: linear-gradient(90deg, rgba(118,200,147,1) 0%, rgba(52,160,164,1) 100%);
}

.ci-received{
    float: left;
    background: rgb(52,160,164);
    background: linear-gradient(90deg, rgba(52,160,164,1) 0%, rgba(26,117,159,1) 100%);
}