.sm-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
    max-height: 100%;
}

.sm-input-parent{
    width: 90vw;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sm-button-parent{
    width: 10vw;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sm-message-input{
    width: 100%;
    padding: 8px 15px;
    font-size: .9em;
    border-radius: 30px;
    outline: none;
    box-sizing: border-box;
    border: none;
}

.sm-message-input:focus{
    outline: none;
}

.sm-message-input-container{
    width: 100%;
    padding: 3px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

.sm-buttonContainer button{
    width: 100%;
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 30px;
    padding: 5px;
    font-size: 1.5em;
    color: white;
    background: #222222;
}

.sm-buttonContainer{
    width: 90%;
    padding: 3px;
    border-radius: 30px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #c775eb, #ff70c6, #ff819a, #ffa674, #ffd061, #f9f871);
    background-size: 200% 200%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}